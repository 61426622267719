import React from "react"
import {Link} from "gatsby"
import tikka from "../../content/assets/img/tikka-small.png"
import languageFromPath from "../api/languageFromPath"
import getTranslationTexts from "../api/getTranslationTexts"
import { graphql } from 'gatsby'

const page404 = (props) => {
// console.log(props);

const language = languageFromPath(props.location.pathname);
const translations = props.data.translations.childMdx.frontmatter.languages;
const translation = getTranslationTexts(language, translations);
// const languages = translations.map((item)=>item.language.code);
// console.log(translation);

  return (
    <div className="bot-page404 posr">
      <div className="bot-page404-in tac">
        {/* <TopMenu></TopMenu> */}
          <p className="bot-page404-text tac">{translation.page404.text}</p>
        <div className="bot-page404-container df aic jcc">
         <div className="bot-page404-letter">4</div>
        <img src={tikka} alt="tikka" className="bot-page404-letter"></img>
          <div className="bot-page404-letter">4</div>
        </div>
        <Link to={translation.nav[0].url} className="btn bot-btn">{translation.nav[0].title}</Link>
        </div>
        {/* <Footer settings={props.settings}></Footer> */}
      </div>
   
  )

}

export const query404 = graphql`
  query query404 {
    translations: file(sourceInstanceName: {eq: "settings"}, name: {eq: "translations"}) {
      childMdx {
        frontmatter {
          languages {
            language {
              code
              nav{
                url
                title
              }
              page404 {
                title
                text
              }
            }
          }
        }
      }
    }
  }
`

export default page404
